<template>
  <el-card style="margin-top: 20px">
    <div style="width:100%;display: flex;justify-content: flex-end;">
      <span class="tool-span" @click="copyJsonFormatContent()">
        <el-icon>
          <DocumentCopy />
        </el-icon> 复制
      </span>
<!--      <span class="tool-span">-->
<!--        <el-icon>-->
<!--          <Download />-->
<!--        </el-icon> 生成文件-->
<!--      </span>-->
    </div>
    <div class="json-box">
      <div style="width: 50%">
        <el-input v-model="jsonStr" :autosize="{ minRows: 35, maxRows: 35 }" type="textarea" resize="none"
          placeholder="请输入 json" @input="clickFormat" />
      </div>
      <div style="width: 50%">
        <json-viewer
            :value="formatStr"
            expand-depth="5"
            boxed
            style="height: 745px; overflow: scroll; overflow-x: unset; overflow-y: unset;"
        />
      </div>
   
    </div>
  </el-card>
</template>

<script>
import { reactive } from "vue";
export default {
  name: "JsonFormat",
  props: ["jsonString"],
  data() {
    return {
      jsonStr: "",
      formatStr: "",
    };
  },
  methods: {
    clickFormat() {
      // 1、JSON.parse：把JSON字符串转换为JSON对象
      // 2、JSON.stringify：把JSON对象 转换为 有缩进的 JSON字符串格式
      if (this.jsonStr !== '') {

        if (!this.checkJsonFormat(this.jsonStr)) {
          this.$message.warn("请正确输入json数据", 3);
          return false;
        }

        if (this.jsonStr == "{}") {
          this.$message.warn("请输入json数据", 1);
          return false;
        }

        // this.formatStr = JSON.stringify(JSON.parse(this.jsonStr), null, "\t");
        this.formatStr = reactive(JSON.parse(this.jsonStr));
        this.$store.jsonStr = undefined;
      } else {

        this.$message.warn("请输入json数据", 1);
      }

    },
    checkJsonFormat(str) {
      if (typeof str != 'string') {
        return false;
      }

      try {
        var obj = JSON.parse(str);
        if (typeof obj == 'object' && obj) {
          return true;
        } else {
          return false;
        }
      } catch (e) {
        return false;
      }
    },
    copyJsonFormatContent() {

      var textarea = document.createElement('textarea')
      if (!this.formatStr) {
        this.$message.error("复制内容为空", 1);
        return false;
      }

      textarea.value = JSON.stringify(this.formatStr, null, "\t");

      document.body.appendChild(textarea)

      textarea.select()

      document.execCommand('copy')

      document.body.removeChild(textarea)

      this.$message.success("复制成功", 1);
    },
    downloadJson() {

      if (!this.formatStr) {
        this.$message.error("下载内容为空", 1);
        return false;
      }

    }
  },
  created() {

    if (this.$store.jsonStr !== undefined) {
      this.jsonStr = this.$store.jsonStr;
      this.clickFormat();
    }
    // console.log("子组件json:",this.jsonString);
  },
  mounted() {
    // console.log("json:",this.jsonString);
  },
};
</script>

<style scoped>
.json-box {
  display: flex;
}

.json-btn {
  margin: auto 20px;
}

.tool-span {
  margin-right: 10px;
  margin-bottom: 2px;
  font-weight: 900;
  text-align: left;
  cursor: pointer;
  color: #606266;
  display: flex;
  align-items: center;
}

:deep(.jv-container .jv-code.boxed) {
  max-height: 100%!important;
  overflow: auto;
}

:deep(.jv-container .jv-more) {
  display: none;
}

</style>