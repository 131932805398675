<template>
  <div class="common-layout">
    <el-container>

      <el-header style="--el-header-padding:0">
        <el-affix :offset="0">
          <header-nav></header-nav>
        </el-affix>
      </el-header>

      <el-main style="--el-main-padding:0">
        <router-view />
      </el-main>

      <el-footer style="--el-footer-padding:0; position: fixed; bottom: 0; width: 100%; height: 40px">
        <Footer></Footer>
      </el-footer>
    </el-container>

  </div>



</template>
<script>
import HeaderNav from "@/layout/HeaderNav.vue";
import Footer from "@/layout/Footer.vue";
import cookie from "vue-cookie";
import md5 from "js-md5";
import { behavior } from "@/api/system";

export default {
  components: {
    HeaderNav,
    Footer,
  },
  created() {
    //定时获取数据
    this.setBehavior();
  },
  beforeUnmount() {
    //离开页面前销毁定时任务
    window.clearInterval(this.setBehaviorTime);
  },
  methods: {
    /**
     * 用户行为分析10秒拉取用户信息
     */
    setBehavior() {
      this.setBehaviorTime = window.setInterval(() => {
        setTimeout(() => {
          this.sendBehaviorLog();
        }, 0);
      }, 10000);
    },

    /**
     * 发送行为记录
     */
    sendBehaviorLog() {
      let cookieData = cookie.get(md5("user-behavior"));
      if (cookieData) {
        behavior({ log: cookieData }).then((res) => {
          if (res.code == 200) {
            //请求成功后删除
            cookie.delete(md5("user-behavior"));
          }
        });
      }
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

body {
  margin: 0;
  padding: 0;
  background-color: #f5f5f6;
}

#nprogress .bar {
  background: #009688 !important; //自定义颜色
}
</style>
