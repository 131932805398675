import CryptoJS from "crypto-js";

const Key = "1463587320654321";
const Iv = "1758326540123456";

/**
 * 加密
 * @param {*} text 
 * @returns 
 */
function encrypt(text) {

    var key = CryptoJS.enc.Utf8.parse(Key); //为了避免补位，直接用16位的秘钥
    var iv = CryptoJS.enc.Utf8.parse(Iv); //16位初始向量
    var encrypted = CryptoJS.AES.encrypt(text, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return encrypted.toString();
}


/**
 * 解密
 * @param {*} text 
 * @returns 
 */
function decrypt(text) {
    var key = CryptoJS.enc.Utf8.parse(Key); //为了避免补位，直接用16位的秘钥
    var iv = CryptoJS.enc.Utf8.parse(Iv); //16位初始向量
    var encrypted = CryptoJS.AES.decrypt(text, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7

    });

    return encrypted.toString(CryptoJS.enc.Utf8);
}

function generatekey(num) {
    let library =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let key = "";
    for (let i = 0; i < num; i++) {
        let randomPoz = Math.floor(Math.random() * library.length);
        key += library.substring(randomPoz, randomPoz + 1);
    }
    return key;
}


export default {
    encrypt, decrypt, generatekey
};
