<template>
  <div>
    <el-card shadow="always" style="border-top-color: #009688; border-top-width: 5px">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">快捷工具</el-breadcrumb-item>
        <el-breadcrumb-item>在线生成条形码</el-breadcrumb-item>
      </el-breadcrumb>
    </el-card>

    <el-card class="box">
      <el-row :gutter="20" class="box-line">
        <el-col class="box-left-title" :span="4">条形码内容:</el-col>
        <el-col :span="20">
          <div><el-input style="height: 40px" v-model="barcode" placeholder="条形码内容"></el-input></div>
        </el-col>
      </el-row>
      <br />
      <el-row :gutter="20" class="box-line">
        <el-col class="box-left-title" :span="4"></el-col>
        <el-col :span="20">
          <div>
            <el-button @click="createBarcode()" color="#009688" v-behavior="{
              title: '在线生成条形码',
              name: '生成条形码',
              type: 'click',
            }">生成条形码</el-button>
          </div>
        </el-col>
      </el-row>
      <br />
      <el-divider />
      <br />

      <el-row :gutter="20" class="box-line">
        <el-col class="box-left-title" :span="4">条形码:</el-col>
        <el-col :span="20">
          <div style="height: 100px; width: 500px;display: flex;align-items: center">

            <svg id="barcode" style="width: 100%;height:100px"></svg>

          </div>
        </el-col>
      </el-row>
      <br />
    </el-card>
  </div>
</template>
<script>
import JsBarcode from 'jsbarcode'

import { message } from 'ant-design-vue';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Barcode",
  data() {
    return {
      barcode: "https://www.makezu.cn/barcode",
    }
  },

  methods: {

    /**
     * 在线生成条形码
     */
    createBarcode() {

      if (!this.barcode) {
        message.warning('请输入条形码内容');
        return false;
      }

      let reg = new RegExp("[\\u4E00-\\u9FFF]+", "g")
      if (reg.test(this.barcode)) {
        message.warning('暂不支持生成包含中文条形码');
        return false;
      }
      
      JsBarcode("#barcode", this.barcode, {
        format: "CODE128",//选择要使用的条形码类型
        displayValue: true,//是否在条形码下方显示文字
        textPosition: "bottom",//设置文本的垂直位置
        background: "#eee",//设置条形码的背景
        font: "monospace",//设置文本的字体
        margin: 10//设置条形码周围的空白边距
      })

    },
  },

  created() {

  },

  mounted() {
    this.createBarcode();
  },

  beforeUnmount() {

  },
};
</script>
<style scoped lang="scss">
.box {
  margin-top: 20px;

  .box-line {
    display: flex;
    align-items: center
  }

  .box-left-title {
    text-align: right;
  }
}
</style>