<template>
  <div class="footer">
    <p>@2023 京ICP备18052625号-2 Makezu.com 版权所有 </p>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Footer",
  data() {
    return {
      // activeIndex: "1-1",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer {
  background-color: #393D49;
  color: #fffc;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 40px;

  p {
    margin: 0;
  }

}
</style>
