<template>
  <div class="login">
    <el-dialog v-model="dialogFormVisible" :close-on-click-modal="false" @close="closeLoginVisible" width="400px">
      <div class="login-item">
        <el-tabs v-model="activeName">

          <!-- <el-tab-pane label="验证码登录" name="phoneLogin">
            <el-form :model="loginForm" :rules="rules" ref="ruleForm">
              <el-form-item prop="username">
                <el-input
                  style="height: 40px"
                  auto-complete="new-password"
                  clearable
                  v-model="loginForm.username"
                  placeholder="请输入手机号或邮箱"
                  prefix-icon="User"
                  autocomplete="off"
                />
              </el-form-item>

              <el-form-item prop="password">
                <el-input
                  style="height: 40px; width: 200px"
                  clearable
                  auto-complete="new-password"
                  v-model="loginForm.password"
                  placeholder="请输入短信验证码"
                  prefix-icon="Lock"
                  autocomplete="off"
                />
              </el-form-item>

              <br />

              <el-button
                type="primary"
                color="#C7000B"
                @click="loginDo('ruleForm')"
                style="width: 100%; height: 40px"
                v-loading="loginLoading"
              >
                <span style="color: #ffffff">立即登录</span>
              </el-button>
            </el-form>
          </el-tab-pane> -->

          <el-tab-pane label="账号登录" name="accLogin">
            <el-form :model="loginForm" :rules="rules" ref="ruleForm">
              <el-form-item prop="username">
                <el-input style="height: 40px" v-model="loginForm.username" placeholder="请输入用户名"
                          prefix-icon="User"
                          autocomplete="off"/>
              </el-form-item>

              <el-form-item prop="password">
                <el-input style="height: 40px" type="password" v-model="loginForm.password" placeholder="请输入密码"
                          show-password prefix-icon="Lock" autocomplete="off"/>
              </el-form-item>

              <el-form-item>
                <ValidateCode status="ruleForm.status" :successFun="successFun" :errorFun="errorFun"></ValidateCode>
              </el-form-item>

              <!-- <div style="display: flex; justify-content: flex-end">
                <el-link :underline="false" type="primary">忘记密码？</el-link>
              </div> -->
              <br>

              <el-button type="primary" :disabled="validate" color="#009688" @click="loginDo('ruleForm')" style="width: 100%; height: 40px"
                         v-loading="loginLoading">
                <span style="color: #ffffff">账号密码登录</span>
              </el-button>
            </el-form>
          </el-tab-pane>

          <p class="login-item-tips">
            未注册账号验证后将自动登录，注册代表同意
          </p>
          <p class="login-item-tips">《Mkz协议》《隐私保护指引》</p>
          <!-- <el-divider>
            <span class="login-item-tips">其他登录方式</span>
          </el-divider> -->
        </el-tabs>

      </div>
    </el-dialog>
  </div>
</template>

<script>
import {accountLogin} from "@/api/login";
import {ElNotification} from 'element-plus';
import { Base64 } from 'js-base64';
import ValidateCode from "@/components/login/ValidateCode.vue";

var checkStatus = (rule, value, callback) => {
  console.log(value);
  if (!value) {
    return callback(new Error("请拖动滑块完成验证"));
  } else {
    callback();
  }
};

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Login",
  props: {
    msg: String,
    changeLoginStatus: Function
  },
  components: {
    ValidateCode
  },
  data() {
    return {
      dialogFormVisible: true,

      loginForm: {
        username: "",
        password: "",
      },

      activeName: "accLogin",

      ruleForm: {
        username: "",
        password: "",
      },

      rules: {
        username: [
          {required: true, message: "请输入账号", trigger: "blur"},
          {min: 3, max: 12, message: '请输入长度在 3 到 12 个字符的用户名', trigger: 'blur'}
        ],

        password: [
          {required: true, message: "请输入密码", trigger: "blur"},
          {min: 6, message: '请输入长度不小于6位字符的密码', trigger: 'blur'}
        ],
      },

      loginLoading: false,

      status: [{ validator: checkStatus, trigger: "change" }],
      validate: true
    };
  },
  methods: {
    closeLoginVisible() {
      this.$store.commit("changeNeedLogin", false);
    },
    /**
     * 登录
     */
    loginDo(formName) {
      this.$refs[formName].validate((valid) => {

        if (valid) {

          this.loginLoading = true;

          let params = {
            username: this.loginForm.username,
            password: this.loginForm.password
          };

          accountLogin(params).then((res) => {

            this.loginLoading = false;

            this.$store.commit("changeNeedLogin", false);

            localStorage.setItem("mkz-auth", res.data.token);

            this.setUserInfo(res.data.token);

            let userinfo = JSON.parse(localStorage.getItem("user-info"))

            this.changeLoginStatus(userinfo.userdata.username)

            for (let i = 0; i < res.data.msg.length; i++) {
              setTimeout(() => {
                ElNotification({
                  title: res.data.msg[i].title,
                  message: res.data.msg[i].content,
                  type: 'success',
                })
              }, i * 1000);
            }
          });

        }
      });
    },

    /**
     * 存储用户信息
     */
    setUserInfo(token) {

      if (!token) {
        return false;
      }

      //按照 . 进行分割
      let tokenArr = token.split('.', 3)
      let data = tokenArr[1];
      let payload = Base64.decode(data);

      localStorage.setItem("user-info", payload);

      return true;
    },

    successFun(status) {
      if (status) {
        this.validate = false
      }
    },

    errorFun(status) {
      if (!status) {
        this.validate = true
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.login {
  .login-item {

    :deep(.el-tabs__nav-wrap::after) {
      display: none;
    }

    :deep(.el-link.el-link--primary) {
      --el-link-text-color: var(--el-color-primary);
      --el-link-hover-text-color: var(--el-color-primary);
      --el-link-disabled-text-color: var(--el-color-primary);
    }

    .login-item-tips {
      text-align: center;
      color: #999999;
      font-size: 12px;
      margin-top: 15px;
    }
  }
}
</style>
