<template>
  <div>
    <el-card
      class="box-card"
      style="border-top-color: #009688; border-top-width: 5px"
    >
      <template #header>
        <div
          class="card-header"
          style="display: flex; justify-content: space-between"
        >
          <span style="font-size: 20px; font-weight: bold">更多工具</span>
          <span style="font-size: 14px; font-weight: bold; color: var(--system-default-color)">
            全部 ></span
          >
        </div>
      </template>

      <!-- <div class="clearfix">
        <span >更多工具</span>
      </div> -->
      <el-timeline :reverse="reverse" style="padding: 0">
        <el-timeline-item
          v-for="(item, index) in list"
          :key="index"
          :color="item.color ? item.color : '#009688'"
        >
          <!-- <el-link :underline="false">Without Underline</el-link> -->
          <router-link :to="item.path" style="font-size: 16px">
            {{ item.name }}
          </router-link>
        </el-timeline-item>
      </el-timeline>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        {
          id: 1,
          name: "时间戳在线转换",
          path: "/timestamp",
        },
        {
          id: 2,
          name: "Json 格式化",
          path: "/json",
        },
        {
          id: 3,
          name: "在线生成条形码",
          path: "/barcode",
        },
        // {
        //   id: 4,
        //   name: "在线接口",
        //   path: "/2",
        // },
      ],
    };
  },
  created() {
    // this.$message.success(
    //   "This is a prompt message for success, and it will disappear in 10 seconds",
    //   10
    // );
  },
  methods: {},
};
</script>
<style scoped lang="scss">
.router-link-active {
  text-decoration: none;
  color: #009688;
}

.router-link-hover-text-color {
  text-decoration: none;
  color: #009688;
}

a {
  text-decoration: none;
  color: rgb(54, 54, 54);
}

:deep(.el-row) {
  display: block;
}

:deep(.el-col) {
  margin-bottom: 20px;
}

.clearfix {
  margin-bottom: 20px;
}
</style>