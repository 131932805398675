import axios from "axios";
import AES from "@/utils/aes.js";

import { message } from 'ant-design-vue';

//生成一个axios的实例
const service = axios.create({
    baseURL: "https://api.makezu.cn/api/v1",
    // baseURL: "http://make.zu.cn/api/v1",
    timeout: 10000,//请求超时
});

/**
 * 请求拦截器
 */
service.interceptors.request.use(
    config => {

        if (config.method != 'post') {
            message.warning('当前网络异常,请稍后重试');
            return false;
        } else {

            config.headers['Content-Type'] = 'application/json'

            config.data.timestamp = parseInt(Date.now() / 1000);

            config.data = {
                'data': AES.encrypt(JSON.stringify(config.data))
            }

            return config
        }

    },
    error => {
        message.warning('当前网络异常,请稍后重试');
        return Promise.reject(error)
    }
)

/**
 * 响应拦截器
 */
service.interceptors.response.use(
    /**
     * 响应前置处理
     */
    response => {

        const res = response.data
        // console.log("响应拦截器", res)

        return res;
    },

    error => {
        return Promise.reject(error)
    }
)

export default service

