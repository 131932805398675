import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import {Base64} from 'js-base64';
import cookie from 'vue-cookie';
import md5 from 'js-md5';
import JsonViewer from "vue3-json-viewer"
import "vue3-json-viewer/dist/index.css";


/**
 * 重置部分组件css
 */
import "@/style/index.css";


const app = createApp(App)

/**
 * 点击事件添加指令
 */
app.directive("behavior", {
    mounted(el, binding) {

        let value = binding.value;

        let operation_type = value.type == "click" ? 2 : "";
        if (!operation_type) {
            // console.log("警告：当前点击按钮未添加指令")
            return false;
        }

        // 点击事件处理逻辑
        el.handler = function () {

            let behaviorCookieSetParam = {
                title: value.title,
                name: value.name,
                type: 1,
                stay_time: 0,
                execute_time: parseInt(Date.now() / 1000),
            };

            saveUserBehavior(behaviorCookieSetParam);

        }

        if (value.type == 'click') {
            // 点击类型，监听点击事件请求记录数据
            el.addEventListener('click', el.handler, false)
        }

    },
    // 元素卸载前移除监听事件
    beforeUnmount(el) {
        el.removeEventListener('click', el.handler)
    }
})

app.use(ElementPlus)
app.use(router)
app.use(VueAxios, axios)
app.use(store)
app.use(Antd)
app.use(Base64)
app.use(JsonViewer)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.mount('#app')

/**
 * 跳转页面进度条
 */
NProgress.configure({
    easing: 'ease', // 动画方式
    speed: 500, // 递增进度条的速度
    showSpinner: false, // 是否显示加载ico
    trickleSpeed: 200, // 自动递增间隔
    minimum: 0.3 // 初始化时的最小百分比
})

/**
 * 路由守卫
 */
router.beforeEach((to, from, next) => {

    //加载路由跳转样式
    NProgress.start()

    if (to.meta.title) {
        document.title = to.meta.title;
    }

    //记录用户页面停留时间
    userStayTimeLog(to, from);

    if (!to.meta.needLogin) {
        next();
        return;
    } else {
        // let userInfo = localStorage.getItem("mkz-token")
        // if (userInfo) {
        //     next();
        //     return;
        // } else {

        //     sessionStorage.setItem("nextUrl", to.path);
        //     //显示登录窗口
        //     store.commit("changeNeedLogin", true);

        //     return;
        // }
        next();
        return;
    }


})

router.afterEach(() => {
    // 关闭路由跳转样式
    NProgress.done()
})

//获取开始时间
let startTime = Date.now();

//定义一个获取用户的函数
const userStayTimeLog = (to, from) => {

    //获取当前时间
    let currentTime = Date.now();

    let browseHistory = {

        prevPath: from.path,
        prevTitle: from.meta.title,
        nowPath: to.path,
        nowTitle: to.meta.title,
        userStayTime: parseInt((currentTime - startTime) / 1000),

    };

    let behaviorCookieSetParam = {
        title: "",
        name: "",
        type: "2",
        stay_time: 0,
        execute_time: parseInt(currentTime / 1000),
    };

    if (!browseHistory.prevTitle) {

        behaviorCookieSetParam.title = browseHistory.nowTitle;
        behaviorCookieSetParam.name = "刷新当前网页";
        saveUserBehavior(behaviorCookieSetParam)

    } else {

        behaviorCookieSetParam.title = browseHistory.prevTitle;
        behaviorCookieSetParam.name = "离开-" + browseHistory.prevTitle;
        behaviorCookieSetParam.stay_time = browseHistory.userStayTime;
        saveUserBehavior(behaviorCookieSetParam)


        behaviorCookieSetParam.title = browseHistory.nowTitle;
        behaviorCookieSetParam.name = "进入-" + browseHistory.nowTitle;
        behaviorCookieSetParam.stay_time = 0;
        saveUserBehavior(behaviorCookieSetParam)
    }


    //重置开始时间
    startTime = Date.now();
};


/**
 * 用户行为记录cookie
 * @param {*} behaviorCookieSetParam
 */
function saveUserBehavior(behaviorCookieSetParam) {

    if (window.origin === 'http://localhost:8080') {
        return false;
    }

    let cookieKey = md5("user-behavior");

    let cookieData = ""; //cookie原数据

    //加密需要存入的值
    behaviorCookieSetParam = Base64.encode(Base64.encode(JSON.stringify(behaviorCookieSetParam)) + "856385676");

    cookieData = cookie.get(cookieKey);

    if (cookieData) {
        cookieData += "," + behaviorCookieSetParam;
    } else {
        cookieData = behaviorCookieSetParam;
    }

    //存入数据
    cookie.set(cookieKey, cookieData);

}

